$font-family-base: 'Roboto', sans-serif;
$primary: #0d3082;
$body-bg: #cfdaf2;
$body-color: #34373d;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$custom-spacers: (
    p10: 10%,
    p15: 15%,
);
$spacers: map-merge($spacers, $custom-spacers);


$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    b: 985px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge($utilities,
        ("bg-opacity": map-merge(map-get($utilities, "bg-opacity"),
                (values: map-merge(map-get(map-get($utilities, "bg-opacity"), "values"),
                        (80: 0.8),
                    ),
                ),
            ),
        ));

@import '~bootstrap/scss/bootstrap.scss';